.artistContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.searchBarContainer.absolute {
  width: 400px;
  position: absolute;
  right: 0px;
  top: 5px;
}

.searchBarContainer.relative {
  position: relative;
  vertical-align: middle;
  margin-top: calc((50vh - 60px));
  width: 64%;
  margin-left: auto;
  margin-right: auto;
}

.informationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding: 5px;
}

#artistInformationDescription {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

#artistInformationDescription.collapsed {
  max-height: 100px;
}

#artistInformationDescription.expanded {
  height: auto;
}

.quickInfoContainer {
  background: #565b6b;
  padding: 5px;
  display: flex;
}

.gTrendsContainer {
  flex-grow: 0;
  margin-top: 45px;
}

#dailyNewFollowersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
}

.value_diff_pos {
  color: chartreuse;
}

.value_diff_neg {
  color: crimson;
}

.chartmetricOptionsButton {
  cursor: pointer;
  border: 2px solid black;
  position: absolute;
  top: 4px;
  right: 403px;
  padding: 5px;
  background: #565b6b;
}

.chartmetricOptions {
  cursor: pointer;
  border: 2px solid black;
  position: absolute;
  top: 42px;
  right: 403px;
  padding: 0px 5px;
  background: #565b6b;
  visibility: hidden;
}

.chartmetricOptions.visible {
  visibility: visible;
}

.chartmetricOption {
  margin: 5px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#chartsContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

#last5TweetsContainer {
  display: flex;
  justify-content: space-between;
}

.tweetPuck {
  padding: 15px;
  margin: 5px;
  border: 1px solid #565b6b;
  border-radius: 8px;
  color: white;
  background: #15202b;
}

#someDownloadLink {
  text-align: right;
  padding-right: 5px;
}